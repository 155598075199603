<template>
  <div class="payment-page bg-base-200">
    <div class="payment-qpay">
      <div class="payment-options">
        <div class="p-header">
          <h2 style="color: hsla(0, 0%, 100%, .8); font-size: 28px">Qpay эрх сунгах</h2>
        </div>

        <div class="payment-options qpay-render">
          <img class="qpay-img" v-if="qpayInvoice != null" :src="`data:image/png;base64,${qpayInvoice.qr_image}`"/>

          <p class="warning-txt">
            Төлбөрөө төлсөн бол "ТӨЛБӨР ШАЛГАХ" товч дээр дарна уу.
            <small style="display: block;">Жич: Төлсөн ч төлөөгүй гэсэн хариу өгч байвал дахин шалгаарай.</small>
          </p>

          <div class="qpay-actions">
            <Button
                style="border: 1px solid #E67E22; color: hsla(0, 0%, 100%, .8)"
                size="small"
                :class="`p-button-outlined`"
                :loading="isLoadingCheck"
                label="Төлбөр шалгах"
                @click="checkPayment"/>

            <Button
                size="small"
                style="border: 1px solid #E67E22; color: hsla(0, 0%, 100%, .8)"
                :class="`p-button-outlined`"
                label="Цуцлах"
                @click="cancelQpay"/>
          </div>
        </div>

        <div v-if="step == 2" class="result-step">
          <div class="qpay-result">
            <div v-if="isSuccess" class="success">
              <div class="icon-wrap">
                <i class="pi pi-check-circle"></i>
              </div>
              <p>{{ resultMsg }}</p>
            </div>

            <div v-else class="error">
              <div class="icon-wrap">
                <i class="pi pi-info-circle"></i>
              </div>
              <p>{{ resultMsg }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@store/_boot/baseApi";
import {mapGetters} from "vuex";

export default {
  name: "qpay",
  components: {},

  data() {
    return {
      isLoading: false,
      isLoadingCheck: false,
      selectedCard: null,
      paymentType: null,
      isDisabled: true,
      qpayInvoice: null,
      isProceeding: false,
      isLoadingQpay: false,
      btnTxt: "Үргэлжлүүлэх",
      accessToken: null,
      bill: null,
      countTimer: null,
      isSuccess: false,
      resultMsg: "",
      step: 1,
    }
  },

  computed: {
    ...mapGetters(['user', 'paymentOptions'])
  },

  created() {
    this.$store.dispatch('getPaymentOptions', {vm: this});
    this.createQpayInvoice()
  },

  methods: {
    resetQpay() {
      clearInterval(this.countTimer);
      this.paymentType = null;
      this.selectedCard = null;
      this.isDisabled = true;
      this.step = 1;
      this.btnTxt = "Үргэлжлүүлэх";
      this.isProceeding = false;
    },

    activeCard(item) {
      if (this.selectedCard == item) {
        this.selectedCard = null;
        this.isDisabled = true;
      } else {
        this.selectedCard = item;
        this.isDisabled = false;
      }
    },

    showPaymentModal(type) {
      if (this.user == null) {
        this.$Message.error("Та нэвтэрч орсны дараа уг үйлдлийг хийх боломжтой!");
        return;
      }
      this.paymentType = type;
      this.$modal.show("payment-modal");
    },


    createQpayInvoice() {
      console.log("iishee orj bna------->")
      this.isProceeding = true;
      this.btnTxt = "Түр хүлээнэ үү";

      api.get(`/api/qpay/invoice/${this.user.id}/${this.$route.query.id}/anime`).then(({data}) => {
        console.log("qpayData------>")
        if (data.status) {
          this.qpayInvoice = data.data;
          console.log("qpayData------>")

          setTimeout(() => {
            // this.step++;
            this.isProceeding = false;
            this.btnTxt = "Үргэлжлүүлэх";
            this.bill = data.bill;

            // this.countTimer = setInterval(() => {
            //   this.count--;
            //   if (this.count === 0) {
            //     this.cancelQpay();
            //     this.$notify.error({
            //       title: 'Хугацаа дууслаа',
            //       message: 'Та төлбөр төлөх хүсэлтээ дахин илгээнэ үү'
            //     });
            //   }
            // }, 1000);
            // this.checkPayment(data.bill);

          }, 1000);
        } else {
          setTimeout(() => {
            this.isProceeding = false;
            this.$notify.error({
              title: 'Алдаа гарлаа',
              message: data.msg,
            });
          }, 1000);
        }
      }).catch(e => {
        console.log(e);
        setTimeout(() => {
          this.isProceeding = false;
          this.$Message.error("Алдаа гарлаа");
          this.$notify.error({
            title: 'Алдаа гарлаа',
            message: "",
          });
        }, 1000);
      })
    },

    checkPayment() {
      this.isLoadingCheck = true;
      api.get(`/api/qpay/check/${this.bill}`).then(({data}) => {
        if (data.status == 1) {
          this.gotoFinal();
          this.isSuccess = true;
          this.resultMsg = data.msg;
        }

        if (data.status == 0) {
          this.gotoFinal();
          this.isSuccess = false;
          this.resultMsg = data.msg;
        }

        if (data.status == 2 && this.step == 1) {
          this.$toast.warning("Төлбөр төлөгдөөгүй байна!")
        }

        this.isLoadingCheck = false;
      }).catch(e => {
        console.log(e);
        this.isLoadingCheck = false;
        this.cancelQpay();
        // this.$notify.error({
        //   title: 'Алдаа гарлаа',
        //   message: data.msg
        // });
      })
    },

    gotoFinal() {
      this.step = 2;
      clearInterval(this.countTimer);
      this.paymentType = null;
      this.selectedCard = null;
      this.isDisabled = true;
      this.isProceeding = false;
      this.count = 90;
    },

    cancelQpay() {
      this.step = 1;
      this.resetQpay();
    }
  }
}
</script>
